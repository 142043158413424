import {Component, inject, input, output, SimpleChanges} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  AbstractControl, FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {InputNumberModule} from 'primeng/inputnumber';
import {CtSettings} from '../../../../models/ct-settings';
import {NgIf} from '@angular/common';
import {InputTextModule} from 'primeng/inputtext';

@Component({
  selector: 'esp-miscellaneous',
  standalone: true,
  imports: [
    TranslateModule,
    InputNumberModule,
    ReactiveFormsModule,
    NgIf,
    InputTextModule
  ],
  templateUrl: './miscellaneous.component.html',
  styleUrl: './miscellaneous.component.css'
})
export class MiscellaneousComponent {
  ctSettings = input<CtSettings>();
  modelChanged = output();
  translateSrv = inject(TranslateService);
  fb = inject(FormBuilder);
  protected backlogForm: FormGroup;
  protected reverseSolveForm: FormGroup;
  constructor() {
    this.backlogForm = this.fb.group({
      backlogInput: new FormControl('0', this.minMaxValidator(0, 99999999))
    });
    this.reverseSolveForm = this.fb.group({
      projectedAsa: new FormControl('0', this.minMaxValidator(0, 99999999))
    });
  }

  isWorkload():boolean {
    return this.ctSettings().workloadCt===true;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ctSettings) {
      this.setBacklog();
    }
  }

  private setBacklog() {
    if (this.isWorkload()) {
      this.backlogForm.controls["backlogInput"].setValue(this.ctSettings().startingBacklog, {emitEvent: false});
      this.backlogForm.controls["backlogInput"].enable();
    } else {
      this.backlogForm.controls["backlogInput"].setValue('', {emitEvent: false});
      this.backlogForm.controls["backlogInput"].disable();
    }
    // console.debug("setBacklog form-status:", this.backlogForm.status);
  }

  public get backlogInput() {
    return this.backlogForm.get("backlogInput").value;
  }

  public get invalidBacklogInput() {
    return this.backlogForm.get("backlogInput").status === "INVALID";
  }

  minMaxValidator(min, max): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valStr = String(control.value).trim();
      const valNum = Number(valStr);
      if (control.value === null || valStr === "" || !Number.isInteger(valNum)
        || valNum < min || valNum > max) {
        return {invalidBacklog: {value: control.value}};
      }

      return null;
    };
  }

  onInput(event: any) {
    this.backlogForm.controls["backlogInput"].setValue(event.value, {emitEvent: true});
  }
}
